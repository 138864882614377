var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-wrapper"},[_c('flicking',{ref:"flicker",attrs:{"options":{
      gap: 10,
      hanger: 0,
      anchor: 0,
      autoResize: true,
      resizeOnContentsReady: true,
    }},on:{"move-end":(e) => {
        this.currentIndex = e.index;
      }}},_vm._l((_vm.items),function(item){return _c('chat-card',{key:item.title,attrs:{"media-url":item.media_url,"title":item.title,"description":item.description,"actions":item.actions},on:{"click":_vm.onClick}})}),1),_vm._v(" "),_c('div',{staticClass:"carousel-controller"},[(this.currentIndex > 0)?_c('button',{staticClass:"prev-button button controller-button",on:{"click":_vm.onClickPrevious}},[_c('img',{attrs:{"src":require("widget/assets/images/left-arrow.svg"),"alt":"<"}})]):_vm._e(),_vm._v(" "),(
        this.items.length > 0 && this.currentIndex < this.items.length - 1
      )?_c('button',{staticClass:"next-button button controller-button",on:{"click":_vm.onClickNext}},[_c('img',{attrs:{"src":require("widget/assets/images/right-arrow.svg"),"alt":">"}})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }