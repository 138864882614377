<template>
  <a
    v-if="isLink"
    :key="action.uri"
    class="action-button button"
    :href="action.uri"
    target="_blank"
    rel="noopener nofollow noreferrer"
    :style="{ borderColor: widgetColor ,color:widgetColor,'--border-color': widgetColor}"
  >
    {{ action.text }}
  </a>
  <button
    v-else-if="action.text"
    :key="action.payload"
    class="action-button button"
    @click="onClick"
    :style="{ borderColor: widgetColor ,color:widgetColor,'--border-color': widgetColor}"
  >
    {{ action.text }}
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  props: {
    action: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isLink() {
      return this.action.type === 'link';
    },
    ...mapGetters({
      widgetColor: 'appConfig/getWidgetColor',
    }),

  },
  methods: {
    onClick() {
      // Do postback here
       this.$parent.$emit('click', this.action);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~widget/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.action-button {
  align-items: center;
  border-radius: $space-micro;
  display: flex;
  font-weight: $font-weight-medium;
  justify-content: center;
  margin-top: $space-smaller;
  max-height: 34px;
  padding: 0;
  width: 100%;
  background-color: white;
  color:$color-primary;
}
.action-button::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border: 2px solid var(--border-color);
  z-index: -1;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Adding a smooth transition to the opacity change */
}
.action-button:hover,.action-button:focus{
  background-color: white;
  box-shadow: 0 0 10px var(--border-color);
  border-color: var(--border-color);
  outline:none;
}
.action-button:hover::before,.action-button:focus::before {
  opacity: 1;
}
</style>
