<template>
  <div class="message-text__wrap">
    <div class="text-content" v-html="message"></div>
    <div v-if="contentAttributes.items">
      <button
        v-for="item in contentAttributes.items"
        :key="item.title"
        class="button nice action-button"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    contentAttributes: {
      type: Object,
      default(){return ''},
    },
  },
};
</script>

<style scoped>
.action-button {

  color: white;
  margin-right: 10px;
}
</style>
