<template>
  <div class="carousel-wrapper">
    <flicking
      ref="flicker"
      :options="{
        gap: 10,
        hanger: 0,
        anchor: 0,
        autoResize: true,
        resizeOnContentsReady: true,
      }"
      @move-end="
        (e) => {
          this.currentIndex = e.index;
        }
      "
    >
      <chat-card
        v-for="item in items"
        :key="item.title"
        :media-url="item.media_url"
        :title="item.title"
        :description="item.description"
        :actions="item.actions"
        @click="onClick"
      >
      </chat-card>
    </flicking>
    <div class="carousel-controller">
      <button
        class="prev-button button controller-button"
        @click="onClickPrevious"
        v-if="this.currentIndex > 0"
      >
        <img src="~widget/assets/images/left-arrow.svg" alt="&lt;" />
      </button>
      <button
        class="next-button button controller-button"
        @click="onClickNext"
        v-if="
          this.items.length > 0 && this.currentIndex < this.items.length - 1
        "
      >
        <img src="~widget/assets/images/right-arrow.svg" alt="&gt;" />
      </button>
    </div>
  </div>
</template>

<script>
import { Flicking } from '@egjs/vue-flicking';
import ChatCard from './ChatCard';

export default {
  name: 'ChatCardCarousel',
  data() {
    return {
      currentIndex: 0,
    };
  },
  components: {
    Flicking,
    ChatCard,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onClick(data) {
      this.$emit('click', data);
    },
    onClickNext() {
      this.$refs.flicker.next();
    },
    onClickPrevious() {
      this.$refs.flicker.prev();
    },
  },
};
</script>

<style lang="scss">
@import '~widget/assets/scss/variables.scss';
.carousel-wrapper {
  position: relative;
  z-index:10;
  width: 16rem;
  .carousel-controller {
    position: absolute;
    top: 25%;
    z-index: 10000;
    width: 100%;

    .controller-button {
      background-color: $color-background-light;
      border: none;
      opacity: 0.9;
      box-shadow: 0 0.5rem 10px rgba(50, 50, 93, 0.4);
      border-radius: 50%;
      color: #000;
      height: 42px;
      width:42px;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-background;
        opacity: 1;
        outline: none;
      }
    }

    .next-button {
      float: right;
    }

    img{
      width:10px;
    }
  }
}
</style>