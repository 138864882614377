<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.GUPSHUP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.GUPSHUP.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_NAME.PLACEHOLDER')"
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

<!-- TODO: Enable this once Gupshup SMS are support -->
       <!-- <div class="medium-8 columns">
        <label :class="{ error: $v.medium.$error }">
          {{ $t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_TYPE.LABEL') }}
          <select v-model="medium">
            <option value="sms">SMS</option>
            <option value="whatsapp">Whatsapp</option>
          </select>
          <span v-if="$v.medium.$error" class="message">{{
            $t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_TYPE.ERROR')
          }}</span>
        </label>
      </div> -->


      <div class="medium-8 columns">
        <label :class="{ error: $v.phoneNumber.$error }">
          {{ $t('INBOX_MGMT.ADD.GUPSHUP.PHONE_NUMBER.LABEL') }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.PHONE_NUMBER.PLACEHOLDER')"
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">{{
            $t('INBOX_MGMT.ADD.GUPSHUP.PHONE_NUMBER.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.appName.$error }">
          {{ $t('INBOX_MGMT.ADD.GUPSHUP.APP_NAME.LABEL') }}
          <input
            v-model.trim="appName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.APP_NAME.PLACEHOLDER')"
            @blur="$v.appName.$touch"
          />
          <span v-if="$v.appName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.GUPSHUP.APP_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.authToken.$error }">
          {{ $t('INBOX_MGMT.ADD.GUPSHUP.AUTH_TOKEN.LABEL') }}
          <input
            v-model.trim="authToken"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.AUTH_TOKEN.PLACEHOLDER')"
            @blur="$v.authToken.$touch"
          />
          <span v-if="$v.authToken.$error" class="message">{{
            $t('INBOX_MGMT.ADD.GUPSHUP.AUTH_TOKEN.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.GUPSHUP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';


export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      appName:'',
      authToken: '',
      medium: 'whatsapp',
      channelName: '',
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    appName:{required},
    channelName: { required },
    phoneNumber: { required },
    authToken: { required },
    medium: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const gupshupChannel = await this.$store.dispatch(
          'inboxes/createGupshupChannel',
          {
            gupshup_channel: {
              name: this.channelName,
              medium: this.medium,
              auth_token: this.authToken,
              phone_number: `${this.phoneNumber.replace(/\D/g, '')}`,
              app_name:this.appName
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: gupshupChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.GUPSHUP.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
